import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function InputLimit(props) {
  const [percentageValue, setPercentageValue] = useState(props.value);

  const handleChange = (event) => {
    setPercentageValue(event.target.value)
    props.setValue(event.target.value)
  }

  return (
    <FormControl size="small" sx={{ width: "100%", minWidth: "180px", minHeight: "42px" }}>
      <InputLabel id="demo-simple-select-label">Overloaded</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={percentageValue}
        label="Percentage"
        onChange={handleChange}
      >
        <MenuItem disabled>Select A Value</MenuItem>
        <MenuItem value={10} disabled={props.percentage_diff > 10}>10%</MenuItem>
        <MenuItem value={20} disabled={props.percentage_diff > 20}>20%</MenuItem>
        <MenuItem value={30} disabled={props.percentage_diff > 30}>30%</MenuItem>
        <MenuItem value={40} disabled={props.percentage_diff > 40}>40%</MenuItem>
        <MenuItem value={50} disabled={props.percentage_diff > 50}>50%</MenuItem>
        <MenuItem value={60} disabled={props.percentage_diff > 60}>60%</MenuItem>
        <MenuItem value={70} disabled={props.percentage_diff > 70}>70%</MenuItem>
        <MenuItem value={80} disabled={props.percentage_diff > 80}>80%</MenuItem>
        <MenuItem value={90} disabled={props.percentage_diff > 90}>90%</MenuItem>
        <MenuItem value={100} disabled={props.percentage_diff > 100}>100%</MenuItem>
      </Select>
    </FormControl>
  )
}