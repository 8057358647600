import React from 'react'

import ToastContext from '@/context/toastContext'
import { ACTIONS } from '@/store/ToastStore'

export function useAsyncRequest() {
  const { dispatch } = React.useContext(ToastContext)
  async function actionWrapper(
    func,
    { successMessage, errorMessage, showMessage } = {},
  ) {
    async function executeAction() {
      try {
        const res = await func()
        if (showMessage) {
          dispatch({
            type: ACTIONS.setToastMessage,
            payload: { message: successMessage || 'Success' },
          })
        }
        return res
      } catch (error) {
        dispatch({
          type: ACTIONS.setToastMessage,
          payload: {
            message: error.response
              ? error.response.data.message
              : errorMessage || 'Something went wrong!',
            type: 'error',
          },
        })
        return false
      }
    }
    return await executeAction()
  }

  return {
    actionWrapper,
  }
}
