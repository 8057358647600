import axiosInstance from '@/utils/axiosInstance'

const resouce = 'overload'
const overloadApi = {
  async create(data) {
    const res = await axiosInstance({
      url: `${resouce}/`,
      method: 'POST',
      data,
    })
    return res.data
  },
  async export() {
    const res = await axiosInstance({
      url: `${resouce}/export`,
      method: 'POST',
    })
    return res.data
  },

  async exportConnected() {
    const res = await axiosInstance({
      url: `${resouce}/export/connection`,
      method: 'POST',
    })
    return res.data
  },
  
  async get() {
    const res = await axiosInstance({
      url: `${resouce}/`,
      method: 'GET',
    })
    return res.data
  },

  async delete(id) {
    const res = await axiosInstance({
      url: `${resouce}/${id}`,
      method: 'DELETE',
    })
    return res.data
  },
  async update(id, data) {
    const res = await axiosInstance({
      url: `${resouce}/${id}`,
      method: 'PATCH',
      data,
    })
    return res.data
  },
}
export default overloadApi
