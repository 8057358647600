import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import AdminLayout from '@/components/layout/AdminLayout'

import PlannerLayout from '../layout/PlannerLayout'

function ProtectedRoute({ children, roleAllowed, isPlanner = false }) {
  const navigate = useNavigate()
  let user = useSessionContext()
  if (
     (roleAllowed?.length > 0
      && !roleAllowed.includes(user?.accessTokenPayload?.user?.accountRole))
  ) {
    navigate('/login', { replace: true })
  }
  return (
    <SessionAuth
      requireAuth={true}
      redirectToLogin={() => {
        navigate('/login', { replace: true })
      }}
    >
      {isPlanner && <PlannerLayout>{children}</PlannerLayout>}
      {!isPlanner && <AdminLayout>{children}</AdminLayout>}
    </SessionAuth>
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ProtectedRoute
