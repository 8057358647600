import PropTypes from 'prop-types'
import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { signOut, useSessionContext } from 'supertokens-auth-react/recipe/session'

import { Logout } from '@mui/icons-material'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import PeopleIcon from '@mui/icons-material/People'
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility'
import GppMaybeIcon from '@mui/icons-material/GppMaybe'
import SourceIcon from '@mui/icons-material/Source'
import { Button } from '@mui/material'
import FlagIcon from '@mui/icons-material/Flag';
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'

import Logo from '@/assets/images/ppm-white.svg'
import colorConstants from '@/constants/color'
import layoutConstants from '@/constants/layout'
import Cookies from 'universal-cookie'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `${layoutConstants.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
)

function PlannerLayout({ children }) {
  const cookies = new Cookies();
  const [open] = React.useState(true)
  const location = useLocation()
  const user = useSessionContext()
  const goBackIntra = async (e) => {
    await signOut()
    window.location.href = 'https://intra.peppermint.company'
  }
  const logout = async () => {
    localStorage.clear()

    cookies.remove('email', { domain: process.env.REACT_APP_SUB_DOMAIN });
    cookies.remove('password', { domain: process.env.REACT_APP_SUB_DOMAIN }); 
    cookies.remove('token', { domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.remove('*', { path: '/', domain: process.env.REACT_APP_SUB_DOMAIN })
    cookies.set("isSignedOut", true, { path: "/", domain: process.env.REACT_APP_SUB_DOMAIN });
    
    await signOut()
    window.location.href = '/login'
  }

  const menuItems = [
    {
      title: 'Schedule',
      key: 'schedule',
      children: [
        {
          title: 'Planning',
          key: 'planning',
          path: '/',
          icon: <CalendarViewMonthIcon />,
        },
      ],
    },
    {
      title: 'Manage',
      key: 'manage',
      children: [
        {
          title: 'Resources',
          key: 'resources',
          path: '/resources',
          icon: <PeopleIcon />,
        },
        {
          title: 'Projects',
          key: 'projects',
          path: '/projects',
          icon: <SourceIcon />,
        },
        {
          title: 'Clients',
          key: 'clients',
          path: '/clients',
          icon: <BusinessCenterIcon />,
        },
        {
          title: 'Holiday',
          key: 'holiday',
          path: '/holiday',
          icon: <BusinessCenterIcon />,
        },
        {
          title: 'Overloaded',
          key: 'overloaded',
          path: '/overloaded',
          roleAllowed: ['ADMIN'],
          icon: <GppMaybeIcon />,
        },
      ],
    },
    {
      title: 'Setting',
      key: 'setting',
      children: [
        {
          title: 'Departments',
          key: 'departments',
          path: '/departments',
          icon: <AccountTreeIcon />,
        },
        {
          title: 'Teams',
          key: 'teams',
          path: '/teams',
          icon: <FlagIcon />,
        },
        {
          title: 'Roles',
          key: 'roles',
          path: '/roles',
          icon: <SettingsAccessibilityIcon />,
        },
      ],
    },
  ]

  return (
    <Box>
      <CssBaseline />
      <Drawer
        sx={{
          width: layoutConstants.drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: layoutConstants.drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: colorConstants.primary,
          },
          '& .active': {
            textDecoration: 'underline',
            backgroundColor: colorConstants.blue,
          },
          '& .MuiListItemButton-root:hover': {
            backgroundColor: colorConstants.blue,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className="flex justify-center items-center my-4">
          <button onClick={goBackIntra}><img src={Logo} alt="logo" /></button>
        </div>
        <List>
          {menuItems.map((headerMenu) => (
            <List
              key={headerMenu.key}
              subheader={
                <ListSubheader
                  sx={{
                    backgroundColor: colorConstants.primary,
                    color: colorConstants.lightgray,
                  }}
                >
                  {headerMenu.title}
                </ListSubheader>
              }
            >
              {headerMenu.children
              .filter((menu) => {
                if (menu.roleAllowed) {
                  return menu.roleAllowed.includes(user?.accessTokenPayload?.user?.accountRole)
                }
                return true
              })
              .map((menu) => (
                <Link to={menu.path} key={menu.key}>
                  <ListItemButton
                    className={location.pathname.includes(menu.key) && 'active'}
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      px: 2,
                      color: colorConstants.lightgray,
                      opacity: 0.7,
                    }}
                  >
                    <div className="w-12">{menu.icon}</div>
                    <ListItemText primary={menu.title} />
                  </ListItemButton>
                </Link>
              ))}
            </List>
          ))}
        </List>
        <Button
            sx={{
              justifyContent: 'center',
              px: 2,
              color: "#EE4B2B",
              marginTop: 'auto',
              width: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.08)',
            }}
            onClick={logout}
          >
            <Box sx={{ mr: "10px" }}>Logout</Box> <Logout />
          </Button>
      </Drawer>
      <Main className=' overflow-hidden'>{children}</Main>
    </Box>
  )
}

PlannerLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default PlannerLayout
