import * as moment from 'moment'
import React, { useEffect, useState } from 'react'

import { ArrowForwardIos } from '@mui/icons-material'
import Add from '@mui/icons-material/Add'
import { MenuItem, Select, Tab, Tabs, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'

import holidayApi from '@/api/holiday'
import TableHeadSortable, {
  getComparator,
} from '@/components/common/TableHeadSort'
import { useAsyncRequest } from '@/utils/actionWrapper'
import Payfit from './Payfit'
import NewPayfit from './NewPayfit'
import TableAction from '@/components/common/TableAction'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

function Holiday() {
  const { actionWrapper } = useAsyncRequest()
  const [value, setValue] = React.useState(0)
  const [holidays, setHoliday] = useState([])
  const [OpenDialog, setOpenDialog] = useState(false)
  const [holidayName, setHolidayName] = useState('')
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('holidayName')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
  const [selectedCountry, setSelectedCountry] = useState(null)
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells = [
    {
      id: 'holidayName',
      label: 'Holiday',
    },
    {
      id: 'startDate',
      label: 'Start Date',
    },
    {
      id: 'endDate',
      label: 'End Date',
    },
    {
      id: 'Country',
      label: 'Team',
    },
    {
      id: 'empty',
      diabledSort: true,
    },
  ]
  const handleNewBusinessUnit = (e) => {
    setHolidayName(e.target.value)
  }
  const handleOpenDialog = () => {
    setOpenDialog(true)
  }
  const handleCloseDialog = () => {
    setHolidayName('')
    setOpenDialog(false)
  }
  const createHoliday = async () => {
    await actionWrapper(
      () =>
        holidayApi.create({
          holidayName,
          startDate,
          endDate,
          Country: selectedCountry,
        }),
      { showMessage: true },
    )
    const businessUnit = await holidayApi.get()
    setHoliday(businessUnit)
    setOpenDialog(false)
  }
  const onDelete = async (id) => {
    await holidayApi.delete(id)
    const res = await holidayApi.get()
    setHoliday(res)
  }
  useEffect(() => {
    const fetchData = async () => {
      const res = await holidayApi.get()
      setHoliday(res)
    }
    fetchData()
  }, [])
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          aria-label="basic tabs example"
        >
          <Tab label="Holiday" />
          <Tab label="Old Payfit" />
          <Tab label="New Payfit" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <div className="text-primary font-bold text-2xl">Holiday</div>
          <Button
            startIcon={<Add />}
            variant="contained"
            onClick={handleOpenDialog}
          >
            Add Holiday
          </Button>
          <Dialog
            open={OpenDialog}
            onClose={handleCloseDialog}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle>Add Holiday</DialogTitle>
            <DialogContent>
              <Stack>
                <p className=" font-semibold text-gray-600 mb-2">
                  Holiday Name (optional)
                </p>

                <TextField
                  autoFocus
                  id="businessUnitName"
                  label="Holiday Name"
                  type="name"
                  fullWidth
                  variant="standard"
                  onChange={handleNewBusinessUnit}
                />
              </Stack>
              <Stack className="my-3">
                <p className=" font-semibold text-gray-600 mb-2 myu">
                  Holiday date
                </p>
                <Stack direction={'row'} spacing={1}>
                  <DatePicker
                    views={['day']}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        required
                        {...params}
                        helperText={null}
                      />
                    )}
                  />
                  <ArrowForwardIos />

                  <DatePicker
                    views={['day']}
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        required
                        helperText={null}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack>
                <p className=" font-semibold text-gray-600 mb-2">
                  Team
                </p>
                <Select
                  value={selectedCountry}
                  required
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  <MenuItem value={'FRANCE'}>France</MenuItem>
                  <MenuItem value={'CAMBODIA'}>Cambodia</MenuItem>
                  <MenuItem value={'TUNISIA'}>Tunisia</MenuItem>
                </Select>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={createHoliday}>Create</Button>
            </DialogActions>
          </Dialog>
        </Stack>
        <Box className=" w-full mt-4">
          <Paper>
            <TableContainer>
              <Table>
                <TableHeadSortable
                  onRequestSort={handleRequestSort}
                  order={order}
                  headCells={headCells}
                  orderBy={orderBy}
                />
                <TableBody>
                  {holidays.length > 0 ? (
                    holidays
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {row.holidayName}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(row.startDate).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {moment(row.endDate).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row?.Country || ''}
                          </TableCell>
                          <TableCell scope="row" align="right">
                          <TableAction
                              handleDelete={() => onDelete(row.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={headCells.length} align="center">
                        No data to show
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            rowsPerPageOptions={[25, 50]}
            rowsPerPage={rowsPerPage}
            count={holidays.length}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Payfit />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NewPayfit />
      </TabPanel>
    </>
  )
}

export default Holiday
