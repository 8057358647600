import { Button, IconButton, Stack } from '@mui/material'
import { Sync, Delete, Download, Edit } from '@mui/icons-material'
import React from 'react'
import PropTypes from 'prop-types'

function TableAction({ file, handleSync, handleDelete, handleEdit, handleDownload, canSync }) {
  function handleOnSync(event) {
    event.stopPropagation()
    handleSync(file)
  }

  function handleOnDelete(event) {
    event.stopPropagation()
    handleDelete(file)
  }

  function handleOnEdit(event) {
    event.stopPropagation()
    handleEdit(file)
  }

  function handleOnDownload(event) {
    event.stopPropagation()
    handleDownload(file)
  }

  return (
    <Stack direction="row" justifyContent="end">
      {canSync && (
        <Button
          startIcon={<Sync />}
          onClick={handleOnSync}
          variant="contained"
          type="primary"
        >
          Sync to Staff
        </Button>
      )}
      {handleEdit && (
      <IconButton onClick={handleOnEdit}>
        <Edit color="primary" fontSize="small" />
      </IconButton>
      )}
      {handleDelete && (
        <IconButton onClick={handleOnDelete}>
          <Delete color="primary" fontSize="small" />
        </IconButton>
      )}
      {handleDownload && (
        <IconButton onClick={handleOnDownload}>
          <Download color="primary" fontSize="small" />
        </IconButton>
      )}
    </Stack>
  )
}

TableAction.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleSync: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDownload: PropTypes.func,
  canSync: PropTypes.bool
}

TableAction.defaultProps = {
  handleSync: null,
  handleDelete: null,
  handleEdit: null,
  handleDownload: null,
  canSync: false
}

export default TableAction
