import PropTypes from 'prop-types'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'

import GuestLayout from '@/components/layout/GuestLayout'

function GuestRoute({ children }) {
  let session = useSessionContext()

  if (session.loading) {
    return null
  }
  if (session.doesSessionExist) {
    return <Navigate to="/" replace />
  }
  return <GuestLayout>{children}</GuestLayout>
}

GuestRoute.propTypes = {
  children: PropTypes.any.isRequired,
}

export default GuestRoute
