import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useAsyncRequest } from '@/utils/actionWrapper';
import overloadApi from '@/api/overload';

export default function ExportButton({setGeneratedFile, setOpenSuccessDialog, loading, setLoading}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const {actionWrapper} = useAsyncRequest()

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };
  const handleExportOverload = async () => {
    setLoading(true)
    const sheet = await actionWrapper(() => overloadApi.export(), {
      showMessage: true,
    })
    if (sheet) {
      setGeneratedFile(sheet.sheetId)
      setOpenSuccessDialog(true)
    }
    setAnchorEl(null);
    setLoading(false)
};
const handleExportConnection = async () => {
  setLoading(true)
  const sheet = await actionWrapper(() => overloadApi.exportConnected(), {
    showMessage: true,
  })
  if (sheet) {
    setGeneratedFile(sheet.sheetId)
    setOpenSuccessDialog(true)
  }
  setAnchorEl(null);
  setLoading(false)

};

  return (
    <>
      <Button
        id="basic-button"
        variant="contained"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleExportOverload} disabled={loading}>Overloaded Project</MenuItem>
        <MenuItem onClick={handleExportConnection} disabled={loading}>Project Not Connected With Profitability</MenuItem>
      </Menu>
    </>
  )
}