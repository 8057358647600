const INITIAL_STATE = Object.freeze({
  events: [],
  loadingEvents: false,
  formatedEvents: {},
  resources: [],
  projects: [],
  clients: [],
  employeeRoles: [],
  departments: [],
  businessUnits: [],
  practices: [],
  statusAlert: {
    show: false,
    message: '',
    type: '',
  },
})

const dataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_EVENTS':
      return {
        ...state,
        events: action.payload,
      }
    case 'SET_FORMATED_EVENTS':
      return {
        ...state,
        formatedEvents: action.payload,
      }
    case 'SET_LOADING_EVENTS':
      return {
        ...state,
        loadingEvents: action.payload,
      }
    case 'SET_RESOURCES':
      return {
        ...state,
        resources: action.payload,
      }
    case 'SET_PROJECTS':
      return {
        ...state,
        projects: action.payload,
      }
    case 'SET_CLIENTS':
      return {
        ...state,
        clients: action.payload,
      }
    case 'SET_EMPLOYEE_ROLES':
      return {
        ...state,
        employeeRoles: action.payload,
      }
    case 'SET_DEPARTMENTS':
      return {
        ...state,
        departments: action.payload,
      }
    case 'SET_BUSINESS_UNITS':
      return {
        ...state,
        businessUnits: action.payload,
      }
    case 'SET_STATUS_ALERT':
      return {
        ...state,
        statusAlert: action.payload,
      }
    case 'SET_PRACTICES':
      return {
        ...state,
        practices: action.payload,
      }
    default:
      return state
  }
}

export default dataReducer
