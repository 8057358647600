import React, { useState } from 'react'

import { Add } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material'
import { useAsyncRequest } from '@/utils/actionWrapper'

import payfitApi from '@/api/payfit'

const FileUpload = ({refreshData, setLoading, isNew=false}) => {
  const { actionWrapper } = useAsyncRequest()
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [filename, setFilename] = useState(null)

  async function handleCreate(event) {
    event.preventDefault()
    const formData = new FormData()

    formData.append('file', selectedFile)
    formData.append('filename', filename)
    formData.append('isNew', isNew)

    setLoading(true)
    await actionWrapper(() => payfitApi.uploadCsv(formData), {
      showMessage: true,
    })
    await refreshData()
    setIsCreateFolderOpen(false)
    setLoading(false)
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }
  const handleFilenameChange = (event) => {
    setFilename(event.target.value)
  }
  async function handleOpenCreateFolderDialog() {
    setIsCreateFolderOpen(true)
  }

  async function handleCloseCreateFolderDialog() {
    setIsCreateFolderOpen(false)
  }
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <div className="text-primary font-bold text-2xl">Leaves & Holidays {isNew? '(New Format)': '(Old Format)'}</div>
        <div className="space-x-4">
          <Button
            startIcon={<Add />}
            onClick={handleOpenCreateFolderDialog}
            variant="contained"
          >
            Upload Leaves & Holidays
          </Button>
        </div>
        <Dialog
          onClose={handleCloseCreateFolderDialog}
          open={isCreateFolderOpen}
        >
          <DialogTitle>
            <InputLabel>Payfit Export</InputLabel>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleCreate} className="w-60">
              <FormControl className="my-4 flex flex-col space-y-5">
                <TextField
                  type="text"
                  name="name"
                  value={filename}
                  onChange={handleFilenameChange}
                  placeholder="e.g Dec-2022-kh"
                  required
                  label="File name"
                  fullWidth
                />
                <TextField type="file" onChange={handleFileChange} required />
              </FormControl>
              <div className="mt-4 flex justify-end">
                <Button type="submit" variant="contained">
                  Upload
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Stack>
    </>
  )
}

export default FileUpload
