import React from 'react'

import { Backdrop, CircularProgress } from '@mui/material'

const Loading = () => {
  return (
    <>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: 'green' }}
        open={true}
      >
        <CircularProgress />
      </Backdrop>
    </>
  )
}

export default Loading
