import React, { useEffect, useState } from 'react'

import { Sync } from '@mui/icons-material'
import {
  Button,
  Stack,
} from '@mui/material'
import { useAsyncRequest } from '@/utils/actionWrapper'
import payfitApi from '@/api/payfit'
import Loading from '@/components/common/Loading'
import FileUpload from './components/fileUpload'
import ListFiles from './components/ListFile'

const NewPayfit = () => {
  const { actionWrapper } = useAsyncRequest()
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(true)

  async function fetchFiles() {
    setLoading(true)
    const res = await actionWrapper(
      () => payfitApi.listFilesNew(),
      {},
      true,
    )
    setFiles(res)
    setLoading(false)
  }
  async function sync() {
    setLoading(true)
    const k = await actionWrapper(() => payfitApi.syncEventsNewPayfit(), {
      showMessage: true,
    })
    console.log(k)
    setLoading(false)
  }
  useEffect(() => {
    fetchFiles()
  }, [])
  return (
    <>
      <FileUpload refreshData={fetchFiles} setLoading={setLoading} isNew={true}/>
      <Stack className='mt-5'>
        <Button startIcon={<Sync />} variant='outlined' onClick={sync}>Sync</Button>
      </Stack>
      <ListFiles files={files} setLoading={setLoading} refreshData={fetchFiles}/>
      {loading && <Loading />}
    </>
  )
}

export default NewPayfit
