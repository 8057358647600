import { ThemeProvider } from '@emotion/react'
import React, { useEffect, Suspense } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'
import Cookie from 'universal-cookie'
import { useNavigate } from 'react-router-dom'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import ToastMessage from '@/components/common/ToastMessage'
import GuestRoute from '@/components/guard/GuestRoutes'
import ProtectedRoute from '@/components/guard/ProtectedRoutes'
import ToastContext from '@/context/toastContext'
import useToastStore from '@/store/ToastStore'
import defautTheme from '@/theme/themes'
import Holiday from './view/Holiday'
import Overloaded from './view/Overloaded'

SuperTokens.init({
  appInfo: {
    appName: 'planner',
    apiDomain: process.env.REACT_APP_SUPERTOKEN_API_DOMAIN || 'https://allocation.peppermint.company',
    websiteDomain: process.env.REACT_APP_SUPERTOKEN_WEB_DOMAIN || 'https://allocation.peppermint.company',
    apiBasePath: process.env.REACT_APP_BASEPATH || '/api/auth',
    websiteBasePath: '/',
  },
  recipeList: [Session.init()],
})

const Login = React.lazy(() => import('@/view/Login'))
const Planner = React.lazy(() => import('@/view/Planner3'))
// const OldPlanner = React.lazy(() => import('@/view/Planner2'))

const Clients = React.lazy(() => import('@/view/Clients'))
const Projects = React.lazy(() => import('@/view/Projects'))
const ProjectDetail = React.lazy(() => import('@/view/Projects/ProjectDetail'))
const Resources = React.lazy(() => import('@/view/Resources'))

const Departments = React.lazy(() => import('@/view/Departments'))
const BusinessUnits = React.lazy(() => import('@/view/BusinessUnit'))
const Roles = React.lazy(() => import('@/view/Roles'))

const MainView = () => {
  const navigate = useNavigate()
  const cookies = new Cookie()

  const handleSetCookie = () => {
    if(!cookies.get("isSignedOut")) {
      // cookies.set("from", "AL", { domain: "localhost" }) //Dev
      cookies.set("from", "AL", { domain: process.env.REACT_APP_SUB_DOMAIN })
    }
    handleRedirect()
  };

  const handleRedirect = () => {
    // window.location.href = "http://localhost:8081" //Dev
    window.location.href = process.env.REACT_APP_INTRA_URL
  }

  useEffect(() => {
    if(!cookies.get("email")) {  
      navigate("/login");
      handleSetCookie()
    }
  }, []);

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <ProtectedRoute isPlanner={true}>
          <Planner />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: '/resources',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/overloaded',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute  roleAllowed={['ADMIN']}>
              <Overloaded />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/projects',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute>
              <ProjectDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/clients',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/holiday',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Holiday />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/departments',
      element: (
        <ProtectedRoute>
          <Departments />
        </ProtectedRoute>
      ),
    },
    {
      path: '/teams',
      element: (
        <ProtectedRoute>
          <BusinessUnits />
        </ProtectedRoute>
      ),
    },
    {
      path: '/roles',
      element: (
        <ProtectedRoute>
          <Roles />
        </ProtectedRoute>
      ),
    },
    {
      path: '/login',
      element: (
        <GuestRoute>
          <Login />
        </GuestRoute>
      ),
    },
    { path: '*', element: <div>Not found</div> },
  ])
  return routes
}
function App() {
  const { state, dispatch } = useToastStore()
  return (
    <SuperTokensWrapper>
      <ToastContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <ToastMessage />
        <ThemeProvider theme={defautTheme}>
          <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Suspense
                fallback={
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1 }}
                    open
                  >
                    <CircularProgress color="primary" />
                  </Backdrop>
                }
              >
                <MainView />
              </Suspense>
            </LocalizationProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ToastContext.Provider>
    </SuperTokensWrapper>
  )
}
export default App
