import React, { useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'

import { useAsyncRequest } from '@/utils/actionWrapper'
import payfitApi from '@/api/payfit'
import TableAction from '@/components/common/TableAction'

const ListFiles = ({setLoading, files, refreshData}) => {
  const { actionWrapper } = useAsyncRequest()

  const handleDeleteFile = async (file) => {
    setLoading(true)
    await actionWrapper(() => payfitApi.deleteFile(file.id), {
      showMessage: true,
    })
    await refreshData()
    setLoading(false)
  }
  return (
    <>
      <TableContainer className="mt-10" component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>

              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() =>
                  window
                    .open(`https://drive.google.com/file/d/${row.id}`, '_blank')
                    .focus()
                }
                className="hover:shadow-md hover:cursor-pointer"
              >
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  <TableAction
                    file={row}
                    handleDelete={() => handleDeleteFile(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ListFiles
