import PropTypes from 'prop-types'
import * as React from 'react'

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'

function GuestLayout({ children }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        minWidth="100vw"
      >
        {children}
      </Box>
    </Box>
  )
}

GuestLayout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default GuestLayout
