import axiosInstance from '@/utils/axiosInstance'

const resouce = 'payfit'
const payfitApi = {
  async listFiles() {
    const res = await axiosInstance({
      url: `${resouce}/old`,
      method: 'GET',
    })
    return res.data
  },
  async listFilesNew() {
    const res = await axiosInstance({
      url: `${resouce}/new`,
      method: 'GET',
    })
    return res.data
  },
  async syncEvents() {
    const res = await axiosInstance({
      url: `${resouce}/sync`,
      method: 'GET',
    })
    return res.data
  },
  async syncEventsNewPayfit() {
    const res = await axiosInstance({
      url: `${resouce}/new/sync`,
      method: 'GET',
    })
    return res.data
  },

  async deleteFile(fileId) {
    const res = await axiosInstance({
      url: `${resouce}/delete-payfit/${fileId}`,
      method: 'DELETE',
    })
    return res.data
  },

  async uploadCsv(data) {
    const res = await axiosInstance({
      url: `${resouce}/upload-csv`,
      method: 'POST',
      data,
      // headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res.data
  },
}
export default payfitApi
